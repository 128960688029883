
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as TagO7V6ELSECkil0RB3xAH5iEvsgterHfDXCYldPNCjmj4Meta } from "/app/pages/theme/Tag.vue?macro=true";
import { default as indexvhfhWVotO_456VvDOq_45cYkZMmeSIieYf1jIKy2UpS3yT0Meta } from "/app/pages/auth/index.vue?macro=true";
import { default as indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as ChipxJ9ctJmubiNBdAInJZovHRR_NcqDCz_456fTd81XzMlYkMeta } from "/app/pages/theme/Chip.vue?macro=true";
import { default as indexsziV31bRWzCkX_45YudaBOdqrwcbympIP9GPi2Nu0StJUMeta } from "/app/pages/about/index.vue?macro=true";
import { default as story2ES3Qu51gfs_45xB4i7814mjiBwcKAAz4shQuWaCWM_LsMeta } from "/app/pages/about/story.vue?macro=true";
import { default as indexODGUxCtM4bWpqANiDXcoaYRgvuMno3DjqK7aDlzK3B4Meta } from "/app/pages/theme/index.vue?macro=true";
import { default as ButtonDdXw_7exk4nxD2H4Piv2kBBJkTxjrNYRZs_RT3lKDukMeta } from "/app/pages/theme/Button.vue?macro=true";
import { default as indexPYMehh1H3I51I_Jt_45B2w91jhAIWvwCrr7OI55V5sjLcMeta } from "/app/pages/profile/index.vue?macro=true";
import { default as Productvq46c_R0Li7UCYPWPUC2hRYUrbpnQ9_45X9QqGk1v_45wr8Meta } from "/app/pages/theme/Product.vue?macro=true";
import { default as TabMenuNNEiCTR88OLrxh9zTjCnDG6hir_45gHEEbjgmKQut1NSQMeta } from "/app/pages/theme/TabMenu.vue?macro=true";
import { default as indexAOzIUj0KPT3SanKgXS_gqMIpkGTgTa_45DL93zj5qyzOgMeta } from "/app/pages/webshop/index.vue?macro=true";
import { default as feedback0Kb0jG_F4R6AJC3rY1_oly7FZvfqr4D3DlS_45iDdF1YUMeta } from "/app/pages/about/feedback.vue?macro=true";
import { default as index7PHmsvhFvB_g5kZxgiuxoeT0bdw2nPdkEwlj4tb_456q4Meta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexLEBIa5TL_7wARFcNk6Qi_56FYxhVIAJbboZEHQSeMrIMeta } from "/app/pages/my-address/index.vue?macro=true";
import { default as restaurantsKw1eqacLRQFb3kf9_y_IjUExRIQvYeXH45FyjMHdZ38Meta } from "/app/pages/about/restaurants.vue?macro=true";
import { default as _91id_93sKhWNRNd83HejKoE5Ncz7LdTDlqedS11R4LcJMEBoRYMeta } from "/app/pages/order-details/[id].vue?macro=true";
import { default as business_45userfn2qhVhvL3jwHsXOb8XoTDuO7fF0F8GW7_tPpIXddzgMeta } from "/app/pages/about/business-user.vue?macro=true";
import { default as indexrX0NHpbjAq_szfF7zCTQpRlZevTgQGK6biG7cXAPs5YMeta } from "/app/pages/check-consent/index.vue?macro=true";
import { default as indexkQoekQkQLz567qFtwWSxvkwxYz39yE3ckpcazuQtodoMeta } from "/app/pages/order-history/index.vue?macro=true";
import { default as indexAcRzFLBrVTc7bgTkCcU49IP0IfTaJj8Hk_45giGw2UwbMMeta } from "/app/pages/favorite-orders/index.vue?macro=true";
import { default as index7ujOokHjWZf8u8zV3_Hdl1xg_ZucFkh0N_2xkTvVCIUMeta } from "/app/pages/payment-methods/index.vue?macro=true";
import { default as student_45discountVmhMHyVwCIxzYB1BiCOqKmPb4Zn2HTOoAFo6S34FdIgMeta } from "/app/pages/about/student-discount.vue?macro=true";
import { default as vacant_45positionsRLgy1Dq4uSpp_45mdbetTMk9f_76UIqcVkC6i_hrAdRqEMeta } from "/app/pages/about/vacant-positions.vue?macro=true";
import { default as company_45at_45peppes16TGbeWvtG0ulX_LAdViOgK3tvCCGxdExSLm1Tpor5oMeta } from "/app/pages/about/company-at-peppes.vue?macro=true";
import { default as privacy_45statementcSCl2a4qgfkFIduWXk_45vuWqwH0AwBWiD08vVz_VOemQMeta } from "/app/pages/about/privacy-statement.vue?macro=true";
import { default as indexZFtTtSUlwTRghMBGuP5HJkECH6XRKiCrgbQJVYa9yJkMeta } from "/app/pages/order-confirmation/index.vue?macro=true";
import { default as indexsPgXg8D3fGBuq_wok4HDf_45eARUhm6vRkZkRs_p_45o3U8Meta } from "/app/pages/payment-method-add/index.vue?macro=true";
import { default as nutritional_45contentpZ6qks6TCSuw51FDCvt18iOybBU4ah0j7Fg5xNOuxKsMeta } from "/app/pages/about/nutritional-content.vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___no",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "theme-Tag___no",
    path: "/theme/Tag",
    component: () => import("/app/pages/theme/Tag.vue")
  },
  {
    name: "theme-Tag___en",
    path: "/en/theme/Tag",
    component: () => import("/app/pages/theme/Tag.vue")
  },
  {
    name: "auth___no",
    path: "/auth",
    component: () => import("/app/pages/auth/index.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/app/pages/auth/index.vue")
  },
  {
    name: "menu___no",
    path: "/menu",
    meta: indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta || {},
    component: () => import("/app/pages/menu/index.vue")
  },
  {
    name: "menu___en",
    path: "/en/menu",
    meta: indexknxF0kVj7_cFA_45cPRX2b7Ih3ctKooxPVBCFHygWs_ggMeta || {},
    component: () => import("/app/pages/menu/index.vue")
  },
  {
    name: "theme-Chip___no",
    path: "/theme/Chip",
    component: () => import("/app/pages/theme/Chip.vue")
  },
  {
    name: "theme-Chip___en",
    path: "/en/theme/Chip",
    component: () => import("/app/pages/theme/Chip.vue")
  },
  {
    name: "about___no",
    path: "/about",
    component: () => import("/app/pages/about/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/app/pages/about/index.vue")
  },
  {
    name: "about-story___no",
    path: "/about/story",
    component: () => import("/app/pages/about/story.vue")
  },
  {
    name: "about-story___en",
    path: "/en/about/story",
    component: () => import("/app/pages/about/story.vue")
  },
  {
    name: "theme___no",
    path: "/theme",
    component: () => import("/app/pages/theme/index.vue")
  },
  {
    name: "theme___en",
    path: "/en/theme",
    component: () => import("/app/pages/theme/index.vue")
  },
  {
    name: "theme-Button___no",
    path: "/theme/Button",
    component: () => import("/app/pages/theme/Button.vue")
  },
  {
    name: "theme-Button___en",
    path: "/en/theme/Button",
    component: () => import("/app/pages/theme/Button.vue")
  },
  {
    name: "profile___no",
    path: "/profile",
    meta: indexPYMehh1H3I51I_Jt_45B2w91jhAIWvwCrr7OI55V5sjLcMeta || {},
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "profile___en",
    path: "/en/profile",
    meta: indexPYMehh1H3I51I_Jt_45B2w91jhAIWvwCrr7OI55V5sjLcMeta || {},
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "theme-Product___no",
    path: "/theme/Product",
    component: () => import("/app/pages/theme/Product.vue")
  },
  {
    name: "theme-Product___en",
    path: "/en/theme/Product",
    component: () => import("/app/pages/theme/Product.vue")
  },
  {
    name: "theme-TabMenu___no",
    path: "/theme/TabMenu",
    component: () => import("/app/pages/theme/TabMenu.vue")
  },
  {
    name: "theme-TabMenu___en",
    path: "/en/theme/TabMenu",
    component: () => import("/app/pages/theme/TabMenu.vue")
  },
  {
    name: "webshop___no",
    path: "/webshop",
    meta: indexAOzIUj0KPT3SanKgXS_gqMIpkGTgTa_45DL93zj5qyzOgMeta || {},
    component: () => import("/app/pages/webshop/index.vue")
  },
  {
    name: "webshop___en",
    path: "/en/webshop",
    meta: indexAOzIUj0KPT3SanKgXS_gqMIpkGTgTa_45DL93zj5qyzOgMeta || {},
    component: () => import("/app/pages/webshop/index.vue")
  },
  {
    name: "about-feedback___no",
    path: "/about/feedback",
    component: () => import("/app/pages/about/feedback.vue")
  },
  {
    name: "about-feedback___en",
    path: "/en/about/feedback",
    component: () => import("/app/pages/about/feedback.vue")
  },
  {
    name: "checkout___no",
    path: "/checkout",
    meta: index7PHmsvhFvB_g5kZxgiuxoeT0bdw2nPdkEwlj4tb_456q4Meta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: index7PHmsvhFvB_g5kZxgiuxoeT0bdw2nPdkEwlj4tb_456q4Meta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "my-address___no",
    path: "/my-address",
    meta: indexLEBIa5TL_7wARFcNk6Qi_56FYxhVIAJbboZEHQSeMrIMeta || {},
    component: () => import("/app/pages/my-address/index.vue")
  },
  {
    name: "my-address___en",
    path: "/en/my-address",
    meta: indexLEBIa5TL_7wARFcNk6Qi_56FYxhVIAJbboZEHQSeMrIMeta || {},
    component: () => import("/app/pages/my-address/index.vue")
  },
  {
    name: "about-restaurants___no",
    path: "/about/restaurants",
    component: () => import("/app/pages/about/restaurants.vue")
  },
  {
    name: "about-restaurants___en",
    path: "/en/about/restaurants",
    component: () => import("/app/pages/about/restaurants.vue")
  },
  {
    name: "order-details-id___no",
    path: "/order-details/:id()",
    meta: _91id_93sKhWNRNd83HejKoE5Ncz7LdTDlqedS11R4LcJMEBoRYMeta || {},
    component: () => import("/app/pages/order-details/[id].vue")
  },
  {
    name: "order-details-id___en",
    path: "/en/order-details/:id()",
    meta: _91id_93sKhWNRNd83HejKoE5Ncz7LdTDlqedS11R4LcJMEBoRYMeta || {},
    component: () => import("/app/pages/order-details/[id].vue")
  },
  {
    name: "about-business-user___no",
    path: "/about/business-user",
    component: () => import("/app/pages/about/business-user.vue")
  },
  {
    name: "about-business-user___en",
    path: "/en/about/business-user",
    component: () => import("/app/pages/about/business-user.vue")
  },
  {
    name: "check-consent___no",
    path: "/check-consent",
    component: () => import("/app/pages/check-consent/index.vue")
  },
  {
    name: "check-consent___en",
    path: "/en/check-consent",
    component: () => import("/app/pages/check-consent/index.vue")
  },
  {
    name: "order-history___no",
    path: "/order-history",
    meta: indexkQoekQkQLz567qFtwWSxvkwxYz39yE3ckpcazuQtodoMeta || {},
    component: () => import("/app/pages/order-history/index.vue")
  },
  {
    name: "order-history___en",
    path: "/en/order-history",
    meta: indexkQoekQkQLz567qFtwWSxvkwxYz39yE3ckpcazuQtodoMeta || {},
    component: () => import("/app/pages/order-history/index.vue")
  },
  {
    name: "favorite-orders___no",
    path: "/favorite-orders",
    meta: indexAcRzFLBrVTc7bgTkCcU49IP0IfTaJj8Hk_45giGw2UwbMMeta || {},
    component: () => import("/app/pages/favorite-orders/index.vue")
  },
  {
    name: "favorite-orders___en",
    path: "/en/favorite-orders",
    meta: indexAcRzFLBrVTc7bgTkCcU49IP0IfTaJj8Hk_45giGw2UwbMMeta || {},
    component: () => import("/app/pages/favorite-orders/index.vue")
  },
  {
    name: "payment-methods___no",
    path: "/payment-methods",
    component: () => import("/app/pages/payment-methods/index.vue")
  },
  {
    name: "payment-methods___en",
    path: "/en/payment-methods",
    component: () => import("/app/pages/payment-methods/index.vue")
  },
  {
    name: "about-student-discount___no",
    path: "/about/student-discount",
    component: () => import("/app/pages/about/student-discount.vue")
  },
  {
    name: "about-student-discount___en",
    path: "/en/about/student-discount",
    component: () => import("/app/pages/about/student-discount.vue")
  },
  {
    name: "about-vacant-positions___no",
    path: "/about/vacant-positions",
    component: () => import("/app/pages/about/vacant-positions.vue")
  },
  {
    name: "about-vacant-positions___en",
    path: "/en/about/vacant-positions",
    component: () => import("/app/pages/about/vacant-positions.vue")
  },
  {
    name: "about-company-at-peppes___no",
    path: "/about/company-at-peppes",
    component: () => import("/app/pages/about/company-at-peppes.vue")
  },
  {
    name: "about-company-at-peppes___en",
    path: "/en/about/company-at-peppes",
    component: () => import("/app/pages/about/company-at-peppes.vue")
  },
  {
    name: "about-privacy-statement___no",
    path: "/about/privacy-statement",
    component: () => import("/app/pages/about/privacy-statement.vue")
  },
  {
    name: "about-privacy-statement___en",
    path: "/en/about/privacy-statement",
    component: () => import("/app/pages/about/privacy-statement.vue")
  },
  {
    name: "order-confirmation___no",
    path: "/order-confirmation",
    component: () => import("/app/pages/order-confirmation/index.vue")
  },
  {
    name: "order-confirmation___en",
    path: "/en/order-confirmation",
    component: () => import("/app/pages/order-confirmation/index.vue")
  },
  {
    name: "payment-method-add___no",
    path: "/payment-method-add",
    component: () => import("/app/pages/payment-method-add/index.vue")
  },
  {
    name: "payment-method-add___en",
    path: "/en/payment-method-add",
    component: () => import("/app/pages/payment-method-add/index.vue")
  },
  {
    name: "about-nutritional-content___no",
    path: "/about/nutritional-content",
    component: () => import("/app/pages/about/nutritional-content.vue")
  },
  {
    name: "about-nutritional-content___en",
    path: "/en/about/nutritional-content",
    component: () => import("/app/pages/about/nutritional-content.vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/webshop/:pathMatch(.*)",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]
import type {
  FavoriteOrders,
  UserAddress,
  UserEntity,
  UserOrderedProductDetails,
  UserOrderHistoryElement,
} from "~/types/entities/user.type";
import { useDelete, useGet, usePost, usePut } from "~/composables/api/customFetch/useCustomFetch";
import type { FavoriteOrderItems } from "~/types/entities/product.type";

export function useUserApis() {
  async function getUser(): Promise<UserEntity | null> {
    try {
      const { data } = await useGet<UserEntity>("/user/getOrCreate", { withToken: true });
      return data.value ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async function editUser(id: string, payload: unknown): Promise<UserEntity | null> {
    try {
      const { data } = await usePut<UserEntity>("/user/" + id, { body: payload, withToken: true });
      return data.value ?? null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async function getUserAddressList(phoneNumber: string): Promise<UserAddress[]> {
    try {
      const { data } = await useGet<UserAddress[]>("/user/address?phoneNumber=" + phoneNumber, {
        withToken: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function getUserOrderHistory(years: string): Promise<UserOrderHistoryElement[]> {
    try {
      const { data } = await useGet<UserOrderHistoryElement[]>("/payment/order/history", {
        withToken: true,
        params: {
          selectedYear: years,
        },
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function getOrderDetails(orderId: string): Promise<UserOrderedProductDetails | null> {
    try {
      const { data } = await useGet<UserOrderedProductDetails>("/payment/order/" + orderId, {
        withToken: true,
      });
      return data.value ?? null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async function getAllFavoriteOrders(userSub: string): Promise<FavoriteOrders[]> {
    try {
      const { data } = await useGet<FavoriteOrders[]>("payment/favoriteOrder/" + userSub, {
        withToken: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function makeAnOrderFavorite(
    userId: string,
    orderId: string,
    orderName: string,
  ): Promise<void> {
    try {
      await usePost<void>("payment/favoriteOrder/", {
        withToken: true,
        body: {
          userId,
          orderId,
          orderName,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function editAnOrderFavorite(
    userId: string,
    orderId: string,
    orderName: string,
  ): Promise<void> {
    try {
      await usePut<void>("payment/favoriteOrder/", {
        withToken: true,
        body: {
          userId,
          orderId,
          orderName,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function markAnOrderUnFavorite(orderId: string): Promise<void> {
    try {
      await useDelete<void>("payment/favoriteOrder/" + orderId, {
        withToken: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function addUserAddress(payload: unknown, phoneNumber: string): Promise<void> {
    try {
      await usePost<void>("user/address/register", {
        withToken: true,
        body: payload,
        params: {
          phoneNumber,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function editUserAddress(payload: unknown, phoneNumber: string, id: string): Promise<void> {
    try {
      await usePost<void>(`user/address/${id}/edit`, {
        withToken: true,
        body: payload,
        params: {
          phoneNumber,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteAddress(id: string, phoneNumber: string): Promise<void> {
    try {
      await useDelete<void>(`user/address/${id}/delete`, {
        withToken: true,
        params: {
          phoneNumber,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function orderHistoryYears(): Promise<string[]> {
    try {
      const { data } = await useGet<string[]>("payment/order/history/years", { withToken: true });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function sendUserOtpMessage(phone: string) {
    try {
      await usePost("/user/otp/sms/" + phone, { withToken: true });
    } catch (e) {
      console.log(e);
    }
  }

  async function verifyOtpForDelete(otp: string, id: string): Promise<void> {
    try {
      await useDelete<void>("/user/" + id, {
        withToken: true,
        params: {
          otp,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function favoriteOrderDetails(id: string): Promise<FavoriteOrderItems[]> {
    try {
      const { data } = await useGet<FavoriteOrderItems[]>("payment/order/product/" + id, {
        withToken: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function signOutBackend(): Promise<void> {
    try {
      await usePost<void>("user/logout", { withToken: true });
    } catch (e) {
      console.log(e);
    }
  }

  return {
    getUser,
    editUser,
    getUserAddressList,
    getUserOrderHistory,
    getOrderDetails,
    getAllFavoriteOrders,
    makeAnOrderFavorite,
    editAnOrderFavorite,
    markAnOrderUnFavorite,
    addUserAddress,
    editUserAddress,
    deleteAddress,
    orderHistoryYears,
    sendUserOtpMessage,
    verifyOtpForDelete,
    favoriteOrderDetails,
    signOutBackend,
  };
}

import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-check": () => import("/app/middleware/authCheck.ts"),
  "default-redirect": () => import("/app/middleware/defaultRedirect.ts"),
  "menu-param-check": () => import("/app/middleware/menuParamCheck.ts"),
  "web-shop-redirect": () => import("/app/middleware/webShopRedirect.ts")
}
import { defineStore } from "pinia";
import { useUserApis } from "~/composables/user/useUserApis";
import type {
  FavoriteOrders,
  UserAddress,
  UserEntity,
  UserOrderHistoryElement,
} from "~/types/entities/user.type";
import usePaymentMethodsApi from "~/composables/api/usePaymentMethodsApi";
import type { PaymentCardType } from "~/types/entities/paymentMethods";
import useVippsAuth from "~/composables/auth/useVippsAuth";
import type { BusinessUser, CompanyDepartment } from "~/types/entities/BusinessUser.type";

const getInitialAuthState = () => {
  if (process.client) {
    return !!localStorage.getItem("access_token");
  }
  return false;
};

interface AuthSliceState {
  authModalVisible: boolean;
  userDetailsEditModal: boolean;
  user: UserEntity | null;
  userAddressList: UserAddress[];
  userAddressAddEditModal: boolean;
  orderHistory: UserOrderHistoryElement[];
  isAuthenticated: boolean;
  paymentCards: PaymentCardType[];
  favoriteOrders: FavoriteOrders[];
  favoritePopUpStatus: boolean;
  orderHistoryYears: string[];
  userDeleteOtpScreen: boolean;
  allBusinessUsers: BusinessUser[];
  selectedBusinessUser: BusinessUser | null;
  selectedDepartment: CompanyDepartment | null;
  selectedRequisitionNumber: string;
}

export const useAuthSlice = defineStore("authSlice", {
  state: (): AuthSliceState => {
    return {
      authModalVisible: false,
      user: null,
      userDetailsEditModal: false,
      userAddressList: [],
      userAddressAddEditModal: false,
      orderHistory: [],
      isAuthenticated: getInitialAuthState(),
      paymentCards: [],
      favoriteOrders: [],
      favoritePopUpStatus: false,
      orderHistoryYears: [],
      userDeleteOtpScreen: false,
      allBusinessUsers: [],
      selectedBusinessUser: null,
      selectedDepartment: null,
      selectedRequisitionNumber: "",
    };
  },
  actions: {
    toggleAuthModal() {
      this.authModalVisible = !this.authModalVisible;
    },
    toggleDeleteOtpScreen() {
      this.userDeleteOtpScreen = !this.userDeleteOtpScreen;
    },
    async getUserDataIfLoggedIn() {
      const { getUser } = useUserApis();
      this.user = await getUser();
    },
    toggleUserDetailsEditModal() {
      this.userDetailsEditModal = !this.userDetailsEditModal;
    },
    async getUserAddressList() {
      const { getUserAddressList } = useUserApis();
      this.userAddressList = await getUserAddressList(this.user?.phone_number || "");
    },
    toggleAddEditAddressModal() {
      this.userAddressAddEditModal = !this.userAddressAddEditModal;
    },
    async getUserOrderHistory(years = "") {
      const { getUserOrderHistory } = useUserApis();
      if (this.user) {
        this.orderHistory = await getUserOrderHistory(years);
      }
    },
    async getRecommendedOrderHistoryYears() {
      const { orderHistoryYears } = useUserApis();
      this.orderHistoryYears = await orderHistoryYears();
    },
    storeSingInToggle() {
      this.isAuthenticated = true;
    },
    async getUserPaymentCardsInfo() {
      const { getAllRegisteredCard } = usePaymentMethodsApi();
      const route = useRoute();
      if (this.user?.sub) {
        const allCards = await getAllRegisteredCard(route.path === "/checkout" ? "Checkout" : "");
        if (this.paymentCards.find((item) => item.id === "invoice")) {
          this.paymentCards = allCards;
          this.manualPushOfInvoiceInPaymentCard();
        } else {
          this.paymentCards = allCards;
        }
      }
    },
    async fetchAllFavoriteOrders() {
      const { getAllFavoriteOrders } = useUserApis();
      if (this.user?.sub) {
        this.favoriteOrders = await getAllFavoriteOrders(this.user.sub);
      }
    },
    toggleFavoriteOrderPopUpStatus() {
      this.favoritePopUpStatus = !this.favoritePopUpStatus;
    },
    async saveAsFavoriteOrder(title: string, orderId: string) {
      const { makeAnOrderFavorite } = useUserApis();
      if (this.user?.sub) {
        await makeAnOrderFavorite(this.user.sub, orderId, title);
      }
    },
    async editAsFavoriteOrder(title: string, orderId: string) {
      const { editAnOrderFavorite } = useUserApis();
      if (this.user?.sub) {
        await editAnOrderFavorite(this.user.sub, orderId, title);
      }
    },
    async addAddress(payload: unknown) {
      const { addUserAddress } = useUserApis();
      if (this.user) {
        await addUserAddress(payload, this.user.phone_number);
      }
    },
    async editAddress(payload: unknown, id: string) {
      const { editUserAddress } = useUserApis();
      if (this.user) {
        await editUserAddress(payload, this.user.phone_number, id);
      }
    },
    async deleteAddress(id: string) {
      const { deleteAddress } = useUserApis();
      if (this.user) {
        await deleteAddress(id, this.user.phone_number);
        await this.getUserAddressList();
      }
    },
    async verifyOtpAndDeleteUser(otp: string) {
      const { verifyOtpForDelete } = useUserApis();
      if (this.user) {
        await verifyOtpForDelete(otp, this.user.sub);
        this.toggleDeleteOtpScreen();
        useVippsAuth().signOut();
      }
    },
    async fetchAllBusinessUser() {
      const { getAllBusinessUser } = usePaymentMethodsApi();
      this.allBusinessUsers = await getAllBusinessUser();
      this.selectedBusinessUser = null;
      this.selectedDepartment = null;
    },
    manualPushOfInvoiceInPaymentCard() {
      const ifExist = this.paymentCards.find((item) => item.id === "invoice");
      console.log(ifExist, "set it up");
      if (!ifExist) {
        this.paymentCards.push({
          cardBrand: "Invoice",
          id: "invoice",
          isDefault: false,
          maskedCardNumber: "",
          provider: "INVOICE",
        });
      }
    },
    clearInvoiceFromPaymentCard() {
      this.paymentCards = this.paymentCards.filter((item) => item.id !== "invoice");
    },
    setSelectedBusinessUser(user: BusinessUser) {
      this.selectedBusinessUser = user;
      this.selectedDepartment = null;
    },
    setRequisitionNumber(val: string) {
      this.selectedRequisitionNumber = val;
    },
    reviseAuthState() {
      this.isAuthenticated = !!localStorage.getItem("access_token");
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

import type { AuthenticationResult, Configuration } from "@azure/msal-browser";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { useAuthSlice } from "~/store/authSlice";

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_AD_PUBLIC_CLIENT_ID,
    authority: import.meta.env.VITE_AD_PUBLIC_AUTHORITY,
    redirectUri: import.meta.env.VITE_AD_PUBLIC_REDIRECT_URL,
    knownAuthorities: [import.meta.env.VITE_AD_PUBLIC_KNOWN_AUTHORITY],
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
    allowRedirectInIframe: true, // Add this for Safari
    loggerOptions: {
      loggerCallback: (_level, message, containsPii) => {
        if (!containsPii) {
          console.log(message);
        }
      },
      piiLoggingEnabled: false,
    },
    iframeHashTimeout: 15000, // Increased timeout
  },
};
// eslint-disable-next-line import/no-mutable-exports
let msalInstance: PublicClientApplication | null = null;
// let initializationInProgress = false;
if (typeof window !== "undefined") {
  if (document.readyState === "complete" || document.readyState === "interactive") {
    initialize();
  } else {
    window.addEventListener("load", () => {
      initialize();
    });
  }
}
/**
 * initialize msal instance
 */
async function initialize() {
  // console.log("initializing msal object");
  try {
    if (
      !process.server &&
      safeLocalStorageGet("authority") &&
      safeLocalStorageGet("authority") === import.meta.env.VITE_AD_PUBLIC_AUTHORITY_PHONE + "/"
    ) {
      msalConfig.auth.authority = import.meta.env.VITE_AD_PUBLIC_AUTHORITY_PHONE;
    }
    msalInstance = new PublicClientApplication(msalConfig);
    // console.log("msal instance", msalInstance);
    await msalInstance.initialize();

    // Handle redirect promise after login or redirect
    await msalInstance
      .handleRedirectPromise()
      .then(handleResponse)
      .catch((err) => {
        throw new Error("Redirect handling error", err);
      });
    // Add event callback for login success
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        console.log("login success");
      }
    });
  } catch (e) {
    console.log("MSAL initialization error", e);
  }
}

/**
 * Safely get a value from localStorage.
 */
function safeLocalStorageGet(key: string): string | null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.warn("LocalStorage access error:", e);
    return null;
  }
}

/**
 * Handles the response after login or redirect.
 * @param {AuthenticationResult | null} resp - The authentication result containing user account information.
 */
function handleResponse(resp: AuthenticationResult | null) {
  if (resp?.account) {
    // console.log("at handle response after getting the url from msal");
    const authStore = useAuthSlice();
    safeLocalStorageSet("access_token", resp.accessToken);
    authStore.storeSingInToggle();
    safeLocalStorageSet("authority", resp.authority);
    useRouter().push("/menu");
  }
}

/**
 * Safely set a value in localStorage.
 */
function safeLocalStorageSet(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.warn("LocalStorage write error:", e);
  }
}

export async function changeConfigForPhoneLogin() {
  msalConfig.auth.authority = import.meta.env.VITE_AD_PUBLIC_AUTHORITY_PHONE;
  await initialize();
  return msalInstance;
}

export default msalInstance;

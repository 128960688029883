import { changeConfigForPhoneLogin } from "~/composables/auth/msalSingletone";
import { useUserApis } from "~/composables/user/useUserApis";

/**
 * A composable function for handling Vipps authentication using MSAL.
 * @returns {{
 *   signIn: () => Promise<void>,
 *   acquireTokenSilent: () => Promise<string | null>,
 *   isAuthenticated: import("vue").ref<boolean>,
 *   signOut: () => void,
 *   currentLoggedInUser: import("vue").ref<{ name: string } | null>
 * }} - Object containing functions and refs for Vipps authentication.
 */
export default function useVippsAuth() {
  const { $msalInstance } = useNuxtApp();
  const loginRequest = {
    scopes: [import.meta.env.VITE_AD_PUBLIC_CLIENT_ID, "openid", "profile", "email"],
  };
  const signIn = async (phone?: boolean) => {
    try {
      if (phone) {
        // console.log("otp login initiate");
        const newInstance = await changeConfigForPhoneLogin();
        await newInstance?.loginRedirect(loginRequest);
      } else {
        // console.log("vipps login initiate");
        await $msalInstance?.loginRedirect(loginRequest);
      }
    } catch (err) {
      console.log("Login error:", err);
    }
  };
  const getHomeId = () => {
    const accounts = $msalInstance?.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return accounts[0].homeAccountId;
    } else {
      return "";
    }
  };
  const signOut = async () => {
    try {
      // console.log("sign out initiate");
      const accountId = getHomeId();
      const account = accountId ? $msalInstance?.getAccountByHomeId(accountId) : null;

      if (account) {
        try {
          const { signOutBackend } = useUserApis();
          signOutBackend();
        } catch (error) {
          console.error("Backend signout failed:", error);
        }
        $msalInstance?.logoutRedirect({
          account,
          onRedirectNavigate: () => {
            localStorage.clear();
            return true;
          },
        });
      } else {
        localStorage.clear();
        window.location.href = "/menu"; // or your login path
      }
    } catch (error) {
      console.error("Signout error:", error);
      localStorage.clear();
      window.location.href = "/menu"; // or your login path
    }
  };
  const acquireTokenSilent = async () => {
    const accounts = $msalInstance?.getAllAccounts();
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      $msalInstance?.setActiveAccount(account);
      try {
        const response = await $msalInstance?.acquireTokenSilent({
          account,
          scopes: [import.meta.env.VITE_AD_PUBLIC_CLIENT_ID, "openid", "profile", "email"],
        });
        return response?.accessToken;
      } catch (err) {
        return null;
      }
    } else {
      console.error("No accounts found");
      return null;
    }
  };
  // const isAuthenticated = computed(() => {
  //   return !!useLocalStorage("access_token", "").value;
  // });
  const currentLoggedInUser = computed(() => {
    const accounts = $msalInstance?.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return {
        name: accounts[0].name,
      };
    }
    return null;
  });

  /**
   * Check if the current token is expired
   * @returns {boolean} - True if the token is expired, false otherwise.
   */
  function isTokenExpired(): boolean {
    // console.log("checking token is expired or not");
    const accounts = $msalInstance?.getAllAccounts();
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      if (account.idTokenClaims && account.idTokenClaims.exp) {
        const tokenExpirationTime = account.idTokenClaims.exp * 1000;
        const currentTime = Date.now();
        return currentTime >= tokenExpirationTime;
      }
    }
    return true;
  }

  async function refreshAccessToken() {
    const accounts = $msalInstance?.getAllAccounts();
    try {
      if (!accounts || accounts.length === 0) {
        // console.error("No accounts found for token refresh");
        await signOut();
        return;
      }

      const response = await $msalInstance?.acquireTokenSilent({
        scopes: [import.meta.env.VITE_AD_PUBLIC_CLIENT_ID, "openid", "profile", "email"],
        account: accounts[0],
        forceRefresh: true, // Force token refresh
      });

      if (response) {
        // console.log("Access token refreshed successfully");
        localStorage.setItem("access_token", response.accessToken);
      }
    } catch (err) {
      console.error("Token refresh error:", err);
      await signOut(); // Ensure user is logged out on refresh error
    }
  }

  return {
    signIn,
    acquireTokenSilent,
    signOut,
    currentLoggedInUser,
    isTokenExpired,
    refreshAccessToken,
  };
}

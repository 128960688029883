import { useRouter } from "vue-router";

export default defineNuxtPlugin((_nuxtApp) => {
  if (process.client) {
    const router = useRouter();
    const { $firebaseTrackingFunctions } = useNuxtApp();
    router.afterEach((to, _from) => {
      // Get the title from the route meta
      const pageTitle = window.document.title || "Untitled Page";

      // Use the head composable to get the current title
      $firebaseTrackingFunctions.trackPageview(to.fullPath, pageTitle);
    });
  }
});

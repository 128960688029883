import { defineStore } from "pinia";

export const useSiteStore = defineStore("siteStore", {
  state: () => {
    return {
      isLoading: false,
      searchPanelVisible: false,
      redirectAction: {
        url: "",
      },
      consentAccepted: false,
      emergencyConsent: true,
      analysisConsentGoogle: true,
      errorReportingConsentToSentry: true,
      navigationMenuVisible: false,
    };
  },
  actions: {
    setIsLoading(val: boolean) {
      this.isLoading = val;
    },
    setRedirectAction(url: string) {
      this.redirectAction.url = url;
    },
    toggleSearchPanel() {
      this.searchPanelVisible = !this.searchPanelVisible;
    },
    acceptConsent() {
      this.consentAccepted = true;
    },
    toggleAnalysisConsentGoogle(val: boolean) {
      this.analysisConsentGoogle = val;
    },
    toggleErrorReportingConsentToSentry(val: boolean) {
      this.errorReportingConsentToSentry = val;
    },
    toggleNavigationMenu() {
      this.navigationMenuVisible = !this.navigationMenuVisible;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

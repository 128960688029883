import * as Sentry from "@sentry/vue";
import { defineNuxtPlugin } from "#app";
import { storeToRefs } from "pinia";
import type { SeverityLevel } from "@sentry/browser";
import { useSiteStore } from "~/store/siteStore";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const { vueApp } = nuxtApp;
    const siteStore = useSiteStore();
    const router = useRouter();
    const { errorReportingConsentToSentry, consentAccepted } = storeToRefs(siteStore);

    // Determine the environment based on the current domain
    const getEnvironment = () => {
      const hostname = window.location.hostname;
      if (hostname === "localhost" || hostname === "127.0.0.1" || hostname === "dev.peppes.no") {
        return "DEVELOPMENT";
      } else if (hostname === "test.peppes.no") {
        return "TEST";
      } else if (hostname === "staging.peppes.no") {
        return "STAGING";
      } else if (hostname === "demo.peppes.no" || hostname === "peppes.no") {
        return "PRODUCTION";
      } else {
        return "UNKNOWN";
      }
    };

    try {
      Sentry.init({
        app: vueApp,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        // Tracing
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.browserProfilingIntegration(),
          Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate: 0.1, // Adjusted to 0.1 as per requirement        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/(demo|dev|test|staging\.)?peppes\.no/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: getEnvironment(),
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        beforeSend(event) {
          if (errorReportingConsentToSentry.value && consentAccepted.value) {
            return event;
          }
          return null;
        },
      });
      // Breadcrumb method
      const addBreadcrumb = (
        message: string,
        category = "custom",
        level: SeverityLevel = "info",
      ) => {
        Sentry.addBreadcrumb({
          message,
          category,
          level,
        });
      };
      // Track Nuxt-specific lifecycle hooks
      nuxtApp.hook("app:error", (error) => {
        Sentry.captureException(error, {
          tags: { source: "nuxt-app-error" },
        });
      });

      nuxtApp.hook("vue:error", (error, instance, info) => {
        Sentry.captureException(error, {
          tags: { source: "vue-error" },
          extra: { componentName: instance?.$options.name, info },
        });
      });

      // Track client-side navigation errors
      router.onError((error) => {
        Sentry.captureException(error, {
          tags: { source: "vue-router-error" },
        });
      });

      // Capture unhandled promise rejections
      if (typeof window !== "undefined") {
        window.addEventListener("unhandledrejection", (event) => {
          Sentry.captureException(event.reason, {
            tags: { source: "unhandled-promise-rejection" },
          });
        });
      }
      // Add navigation breadcrumbs
      router.beforeEach((to, from) => {
        addBreadcrumb(`Navigation from ${from.path} to ${to.path}`, "navigation");
      });

      return {
        provide: {
          Sentry,
        },
      };
    } catch (error) {
      console.error("Failed to initialize Sentry:", error);
    }
  }
});

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useSiteStore } from "~/store/siteStore";

export default defineNuxtPlugin(() => {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  };

  if (process.client) {
    const siteStore = useSiteStore();
    const { analysisConsentGoogle, consentAccepted } = storeToRefs(siteStore);
    const hasConsent = computed(() => consentAccepted.value && analysisConsentGoogle.value);

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const trackingFunctions = {
      trackPageview: (pageUrl: string, pageTitle: string) => {
        if (hasConsent.value) {
          logEvent(analytics, "page_view", { page_location: pageUrl, page_title: pageTitle });
        }
      },
      trackLogin: (eventType: "attempt" | "success", userId?: string) => {
        const params: Record<string, string> = { event_type: eventType };
        if (userId && eventType === "success") {
          params.user_id = userId;
        }
        if (hasConsent.value) {
          logEvent(analytics, "login", params);
        }
      },
      trackSearch: (query: string, resultCount: number) => {
        if (hasConsent.value) {
          logEvent(analytics, "search", { search_term: query, results_count: resultCount });
        }
      },
      trackBeginCheckout: (totalPrice: number, itemCount: number) => {
        if (hasConsent.value) {
          logEvent(analytics, "begin_checkout", { value: totalPrice, items: itemCount });
        }
      },
      trackAbandonedCart: (cartValue: number, timeElapsed: number) => {
        if (hasConsent.value) {
          logEvent(analytics, "abandoned_cart", { value: cartValue, time_elapsed: timeElapsed });
        }
      },
      trackRemoveFromCart: (productCode: string) => {
        if (hasConsent.value) {
          logEvent(analytics, "remove_from_cart", { item_id: productCode });
        }
      },
      trackSelectRecommendedProduct: (productCode: string) => {
        if (hasConsent.value) {
          logEvent(analytics, "select_item", {
            item_id: productCode,
            item_list_name: "Recommended",
          });
        }
      },
      trackViewProductDetails: (productCode: string) => {
        if (hasConsent.value) {
          logEvent(analytics, "view_item", { item_id: productCode });
        }
      },
      trackTransaction: (
        status: "attempt" | "success",
        amount: number,
        items: Array<{
          id: string;
          quantity: number;
          price: number;
        }>,
      ) => {
        if (status === "success" && hasConsent.value) {
          logEvent(analytics, "purchase", {
            currency: "NOK",
            value: amount,
            items: items.map((item) => ({
              item_id: item.id,
              item_name: item.id, // Assuming id can be used as name
              quantity: item.quantity,
              price: item.price,
            })),
          });
        } else if (hasConsent.value) {
          logEvent(analytics, "begin_checkout", {
            currency: "NOK",
            value: amount,
            items: items.map((item) => ({
              item_id: item.id,
              item_name: item.id, // Assuming id can be used as name
              quantity: item.quantity,
              price: item.price,
            })),
          });
        }
      },
    };

    return {
      provide: {
        firebaseTrackingFunctions: trackingFunctions,
        logGoogleEvent: (eventName: string, params?: Record<string, string | number | object>) => {
          logEvent(analytics, eventName, params);
        },
      },
    };
  }
});

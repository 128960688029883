import { useDelete, useGet, usePost } from "~/composables/api/customFetch/useCustomFetch";
import type { PaymentCardType } from "~/types/entities/paymentMethods";
import { useSiteStore } from "~/store/siteStore";
import type { BusinessUser, Customer } from "~/types/entities/BusinessUser.type";

export default function usePaymentMethodsApi() {
  async function verifyBeforeAddingCard(): Promise<string> {
    try {
      const { data } = await useGet<string>("payment/verify?paymentProvider=Swedbank", {
        withToken: true,
      });
      return data.value ?? "";
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  async function registerCardInAccount(
    userId: string,
    provider: string,
    payerRef?: string,
  ): Promise<string> {
    const siteStore = useSiteStore();
    const { redirectAction } = storeToRefs(siteStore);
    try {
      const { data } = await usePost<void>("payment/paymentMethod", {
        withToken: true,
        body: {
          paymentProvider: provider,
          payerRef,
          userId,
        },
      });
      return data.value ?? "";
    } catch (e) {
      if (redirectAction.value.url) {
        const redirectUrl = redirectAction.value.url;
        siteStore.setRedirectAction("");
        navigateTo(redirectUrl);
      }
      return "";
    }
  }

  async function getAllRegisteredCard(context: string): Promise<PaymentCardType[]> {
    try {
      const { data } = await useGet<void>("payment/payment-methods", {
        withToken: true,
        params: {
          context,
        },
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function deleteCard(providerId: string) {
    try {
      const { data } = await useDelete("payment/provider/delete?providerId=" + providerId, {
        withToken: true,
      });
      return data.value ?? "";
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  async function makeCardDefault(paymentMethodId: string, userId: string) {
    try {
      const { data } = await usePost("payment/paymentMethod/default", {
        params: {
          paymentMethodId,
          userId,
        },
        withToken: true,
      });
      return data.value ?? "";
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  async function getAllBusinessUser(): Promise<BusinessUser[]> {
    try {
      const { data } = await useGet<BusinessUser[]>("/user/business-user", {
        withToken: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function verifyBusinessUser(
    phoneNumber: string,
    companyNumber: string,
    pin: string,
  ): Promise<Customer | null> {
    try {
      const { data } = await usePost<Customer>("/user/business-user", {
        body: {
          phoneNumber,
          companyNumber,
          pin,
        },
        withToken: true,
      });
      return data.value ?? null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  return {
    verifyBeforeAddingCard,
    registerCardInAccount,
    getAllRegisteredCard,
    deleteCard,
    makeCardDefault,
    getAllBusinessUser,
    verifyBusinessUser,
  };
}

import type { UseFetchOptions } from "#app";
import { defu } from "defu";
import { useFetch } from "nuxt/app";
import LoaderController from "~/controllers/LoaderController";
import useVippsAuth from "~/composables/auth/useVippsAuth";

/**
 * Custom fetch hook
 * @param {string} url - URL to fetch
 * @param {unknown} options - Options to pass to useFetch
 * @param {"GET" | "POST" | "PUT" | "DELETE"} method - HTTP method
 * @returns {unknown} - Returns a promise
 */
function useCustomFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
) {
  const { isTokenExpired, refreshAccessToken } = useVippsAuth();
  const defaults: UseFetchOptions<T> = {
    baseURL: import.meta.env.VITE_API_BASE_URL,
    key: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    onResponse: (_ctx) => {
      LoaderController.hideLoader();
      // Handle successful response here
      return _ctx;
    },
    onResponseError: (_ctx) => {
      LoaderController.hideLoader();
      if (
        typeof window !== "undefined" &&
        (_ctx.response._data.title || _ctx.response._data.detail)
      ) {
        useNuxtApp().$toast.error(_ctx.response._data.title || _ctx.response._data.detail || "");
      }
      // Capture API error with Sentry
      useNuxtApp().$Sentry.captureException(_ctx.error, {
        tags: { source: "api-error" },
        extra: {
          url: _ctx.request,
          method,
          status: _ctx.response.status,
          data: _ctx.response._data,
        },
      });
      return Promise.reject(_ctx.error);
    },
    onRequest: async function (_ctx) {
      try {
        if (!_ctx.options.disableGlobalLoader) {
          LoaderController.showLoader();
        }
        if (_ctx.options.withToken === true && localStorage.getItem("access_token")) {
          if (isTokenExpired()) {
            console.log("token expired");
            await refreshAccessToken();
          }

          const token = localStorage.getItem("access_token") || "";
          if (token) {
            // Set the Authorization header in the request options
            _ctx.options.headers = {
              ..._ctx.options.headers,
              Authorization: `Bearer ${token}`,
            };
          }
        }
        return _ctx;
      } catch (e) {
        console.log(e);
      }
    },
    onRequestError: (_ctx) => {
      LoaderController.hideLoader();
      // Handle request error
      console.log(_ctx);
      useNuxtApp().$Sentry.captureException(_ctx.error, {
        tags: { source: "api-request-error" },
        extra: {
          url,
          method,
        },
      });
      return Promise.reject(_ctx.error);
    },
  };
  const params = defu(options, defaults);
  params.method = method;

  return useFetch(url, params);
}

/**
 * Abstractions for different HTTP methods
 * @param {string} url - URL to fetch
 * @param {unknown} options - Options to pass to useFetch
 * @returns {unknown} - Returns a promise
 */
export function usePost<T>(url: string, options = {}) {
  return useCustomFetch<T>(url, options, "POST");
}

/**
 * Abstractions for different HTTP methods
 * @param {string} url - URL to fetch
 * @param {unknown} options - Options to pass to useFetch
 * @returns {unknown} - Returns a promise
 */
export function useGet<T>(url: string, options = {}) {
  return useCustomFetch<T>(url, options, "GET");
}

/**
 * Abstractions for different HTTP methods
 * @param {string} url - URL to fetch
 * @param {unknown} options - Options to pass to useFetch
 * @returns {unknown} - Returns a promise
 */
export function usePut<T>(url: string, options = {}) {
  return useCustomFetch<T>(url, options, "PUT");
}

/**
 * Abstractions for different HTTP methods
 * @param {string} url - URL to fetch
 * @param {unknown} options - Options to pass to useFetch
 * @returns {unknown} - Returns a promise
 */
export function useDelete<T>(url: string, options = {}) {
  return useCustomFetch<T>(url, options, "DELETE");
}
